export enum WhitelabelDomainsEnum {
  DORNIER = "https://interviews.dornier.io",
  OUTSTAFFER = "https://interviewai.outstaffer.com",
}

export const WHITELABEL_SUPPORT_EMAILS: Record<WhitelabelDomainsEnum, string> =
  {
    [WhitelabelDomainsEnum.DORNIER]: "support@alpharun.com",
    [WhitelabelDomainsEnum.OUTSTAFFER]: "support@outstaffer.com",
  };

export const getFaviconForDomain = (domain: string) => {
  // This value is hard coded to the favicon uploaded by Outstaffer.
  // If we have more customers that want full domain whitelabeling, their favicon URL will need to be added here.
  if ((domain as WhitelabelDomainsEnum) === WhitelabelDomainsEnum.OUTSTAFFER)
    return "https://auth.alpharun.com/storage/v1/object/public/public-assets/favicons/favicon-5ca5dd2e-6f68-4c45-94b1-045417e87bc5.ico";

  return null;
};
