import axios, { AxiosError } from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";

const MAX_RETRIES = 3; // Maximum number of retries
const RETRY_DELAY_MS = 1000; // Delay between retries in milliseconds
const REQUEST_TIMEOUT_MS = 10000; // 10 second timeout

interface AxiosInstanceOptions {
  enableRetries?: boolean;
  enforceTimeout?: boolean;
}

export const createAxiosInstance = (
  token?: string | null,
  options: AxiosInstanceOptions = {},
) => {
  const instance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    withCredentials: true, // Send cookies with the request.
    timeout: options.enforceTimeout ? REQUEST_TIMEOUT_MS : undefined,
    headers: token
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      : {
          "Content-Type": "application/json",
        },
  });

  instance.interceptors.request.use((config) => {
    const shortId = getShortIdFromUrl();
    if (shortId) {
      config.headers["x-project-short-id"] = shortId; // Use a custom header to send the shortId
    }
    return config;
  });

  if (options.enableRetries) {
    const retryConfig: IAxiosRetryConfig = {
      retries: MAX_RETRIES,
      retryDelay: (retryCount) => RETRY_DELAY_MS * retryCount,
      retryCondition: (error) => {
        // Only retry if the error is a 5xx error or a network error
        return Boolean(
          axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            (error.response && error.response?.status >= 500),
        );
      },
      onRetry: (retryCount: number, error: AxiosError) => {
        console.log(`Retry attempt ${retryCount} for ${error?.config}`);
      },
    };

    axiosRetry(instance, retryConfig);
  }

  return instance;
};

export function getShortIdFromUrl() {
  const path = window.location.pathname;
  const pathSegments = path.split("/"); // split the path by '/'

  // Check if the first segment is 'i' and if there is a segment after 'i'
  if (pathSegments.length > 2 && pathSegments[1] === "i") {
    return pathSegments[2];
  }

  return null; // Return null if the URL doesn't match the expected pattern
}
