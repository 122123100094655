import { ComplexInterviewQuestion } from "app-types";
import { FC } from "react";
import { MatrixSurveyQuestionSection } from "./matrixSurveyQuestionSection";
import {
  MultipleChoiceQuestionSection,
  SurveyQuestionBaseProps,
} from "./multipleChoiceQuestionSection";

export const SurveyQuestionSection: FC<
  SurveyQuestionBaseProps & { question: ComplexInterviewQuestion }
> = (props) => {
  switch (props.question.type) {
    case "multiple_choice":
      return (
        <MultipleChoiceQuestionSection
          key={props.question.id}
          {...props}
          question={props.question}
        />
      );
    case "rating":
      return (
        <MatrixSurveyQuestionSection
          key={props.question.id}
          {...props}
          question={props.question}
        />
      );
  }
};
