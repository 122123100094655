import type { Interview } from "../entities/interview";
import { isValidEmail, splitSummaryTextIntoLines } from "./utilities";

export const getInterviewInsightsSummaryText = (interview: Interview) => {
  const { strengths, weaknesses, summary } = interview.insights || {};
  if (summary) return splitSummaryTextIntoLines(summary).join("\n");

  if (
    !strengths ||
    !weaknesses ||
    (strengths.length === 0 && weaknesses.length === 0)
  )
    return "";

  return `Strengths:\n${strengths.join("\n")}\n\nWeaknesses:\n${weaknesses.join(
    "\n",
  )}`;
};

export enum InterviewPageSpecialModesEnum {
  InitialLandingPage = "ilp", // Used by Fountain integration to redirect applicants to the interview page
}

export const INTERVIEW_LINK_PARAMS = {
  EMAIL: "e",
  MODE: "m",
} as const;

export const parseInterviewLinkParams = (
  urlSearchString: string,
): {
  email?: string;
  mode?: InterviewPageSpecialModesEnum;
} => {
  try {
    const params = new URLSearchParams(urlSearchString);

    const email = params.get(INTERVIEW_LINK_PARAMS.EMAIL);
    const mode = params.get(INTERVIEW_LINK_PARAMS.MODE);

    return {
      email: email && isValidEmail(email, true) ? email : undefined,
      mode:
        mode &&
        Object.values(InterviewPageSpecialModesEnum).includes(
          mode as InterviewPageSpecialModesEnum,
        )
          ? (mode as InterviewPageSpecialModesEnum)
          : undefined,
    };
  } catch {
    return {};
  }
};
