import {
  getRatingsFromSurveyScale,
  MatrixInterviewQuestionPublic,
  RatingSurveyResponse,
} from "app-types";
import { FC, useRef, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  RadioButtonsGroup,
  SurveyQuestionTable,
} from "ui";
import { InterviewQuestionSection } from "../interview/interviewQuestionSection";
import { SurveyQuestionBaseProps } from "./multipleChoiceQuestionSection";

const focusStyle = "focus:outline focus:outline-1 focus:outline-indigo-600";

export const MatrixSurveyQuestionSection: FC<
  SurveyQuestionBaseProps & { question: MatrixInterviewQuestionPublic }
> = ({ question, answer, interview, onSaveComplexAnswer }) => {
  // A rating of 0 is unselected - valid ratings are 1 to 5.
  const [ratings, setRatings] = useState<RatingSurveyResponse[]>(
    question.options.map((option) => ({ name: option.name, rating: 0 })),
  );

  // The options users need to rate.
  const surveyOptions = question.options.map((option) => option.name);
  // The ratings users can give to the options (e.g. "Very important").
  const possibleRatings = getRatingsFromSurveyScale(question.scale);

  const desktopRadioRowRefs = surveyOptions.map((o) => {
    return useRef<HTMLTableCellElement>(null);
  });
  const mobileRadioGroupRefs = surveyOptions.map((o) => {
    return useRef<HTMLDivElement>(null);
  });

  // Should never happen since we currently only support rating questions.
  if (question.type !== "rating") {
    return null;
  }

  const handleRatingChange = (optionName: string, rating: number) => {
    setRatings((prevRatings) =>
      prevRatings.map((item) =>
        item.name === optionName ? { ...item, rating: rating } : item,
      ),
    );
  };

  return (
    <div className="flex flex-col items-center min-h-full sm:min-h-0">
      <div className="box-border pt-6 pl-6 pr-6 w-full">
        <InterviewQuestionSection
          fragmentOrSurveyQuestion={question}
          interview={interview}
        />

        {/* Desktop UI - table with radio buttons */}
        <div className="hidden sm:block">
          <SurveyQuestionTable
            columnNames={possibleRatings.map((r) => r.name)}
            rowNames={surveyOptions}
            rowRefs={desktopRadioRowRefs}
            onChange={handleRatingChange}
            currentRatings={ratings}
          />
        </div>

        {/* Mobile UI - radio groups, not rows */}
        <div className="sm:hidden space-y-4 my-4">
          {surveyOptions.map((rowName, rowIndex) => (
            <div
              key={`select-${rowName}-${rowIndex}`}
              ref={mobileRadioGroupRefs[rowIndex]}
              className={focusStyle}
              tabIndex={rowIndex}
            >
              <div className="text-m font-medium mb-2">{rowName}</div>
              <RadioButtonsGroup
                options={possibleRatings.map((r) => ({ name: r.name }))}
                value={
                  possibleRatings.find(
                    (r) =>
                      r.rating ===
                      ratings.find((rating) => rating.name === rowName)?.rating,
                  )?.name || null
                }
                onChange={(value) => {
                  const newRating = possibleRatings.find(
                    (r) => r.name === value,
                  );

                  if (newRating) handleRatingChange(rowName, newRating.rating);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end p-3 w-full border-t border-gray-200 space-x-2">
        <Button
          onClick={() => {
            // Find the first invalid row (if any)
            let missingRatingIndex: number | undefined;
            for (let index = 0; index < ratings.length; index++) {
              if (ratings[index].rating === 0) {
                missingRatingIndex = index;
                break; // Stop the loop once a matching case is found
              }
            }

            // If there's a missing rating, focus the corresponding row on either desktop or mobile
            if (missingRatingIndex !== undefined) {
              const matchingDesktopRadioRow =
                desktopRadioRowRefs[missingRatingIndex].current;
              const matchingMobileRadioGroup =
                mobileRadioGroupRefs[missingRatingIndex].current;

              if (
                matchingDesktopRadioRow &&
                getComputedStyle(matchingDesktopRadioRow).display !== "none"
              )
                matchingDesktopRadioRow.focus();
              if (matchingMobileRadioGroup) {
                matchingMobileRadioGroup.focus();
              }

              return;
            }

            return onSaveComplexAnswer({
              ...answer,
              response: ratings,
            });
          }}
          variant={ButtonVariantsEnum.Primary}
          label="Continue"
        />
      </div>
    </div>
  );
};
