import {
  DEMO_INTERVIEW_ID,
  getFaviconForDomain,
  InterviewAccessErrorsEnum,
  InterviewPageSpecialModesEnum,
  parseInterviewLinkParams,
  PosthogEventTypesEnum,
  ProjectModesEnum,
} from "app-types";
import posthog from "posthog-js";
import { useEffect } from "react";
import {
  isAndroid,
  isChrome,
  isEdge,
  isIOS,
  isMobile,
  isSafari,
} from "react-device-detect";
import { Loader, LoaderStylesEnum } from "ui";
import "ui/styles.css";
import { getShortIdFromUrl } from "../api/axiosConfig";
import { DemoInterviewBanner } from "../features/interview/demoInterviewBanner";
import { InterviewRecorder } from "../features/interview/interviewRecorder";
import { InterviewCompletedSection } from "../features/interviewCompleted/interviewCompletedSection";
import { InterviewLandingPage } from "../features/interviewLandingPage/interviewLandingPage";
import {
  selectProjectLinkState,
  validateProjectLink,
} from "../features/projectLink/projectLinkSlice";
import {
  getInterviewData,
  selectTranscriptFragmentsState,
} from "../features/transcriptFragments/transcriptFragmentsSlice";
import { VoiceAgentInterviewer } from "../features/voiceAgentInterview/voiceAgentInterviewer";
import { getSupportEmail } from "../helpers/utilities";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import "./styles.css";

const interviewAccessErrorEnumToErrorMessage = {
  [InterviewAccessErrorsEnum.NOT_FOUND]:
    "Invalid interview link. Please confirm that you are accessing the correct link.",
  [InterviewAccessErrorsEnum.NOT_LIVE]:
    "The project for this interview link isn't launched yet. If you're the owner of the project, please launch it before sharing the interview link.",
  [InterviewAccessErrorsEnum.LIMIT_REACHED]:
    "Sorry, but we've reached the maximum number of interviews for this research study. Thanks for your interest and we hope to be able to get your feedback soon.",
  [InterviewAccessErrorsEnum.RATE_LIMIT_EXCEEDED]:
    "Too many interview requests. Please try again later.",
  [InterviewAccessErrorsEnum.UNKNOWN]:
    "An unknown error occured. Please refresh and try again.",
};

function App(): JSX.Element {
  const { interview, interview_loading_status, interview_error } =
    useAppSelector(selectTranscriptFragmentsState);
  const {
    status: projectLinkStatus,
    error: projectLinkError,
    project,
    isEmailValidationRequired,
  } = useAppSelector(selectProjectLinkState);

  const dispatch = useAppDispatch();

  const projectLinkShortId = getShortIdFromUrl();

  const supportEmail = getSupportEmail();

  // Initial fetch of the Project Link or Interview
  useEffect(() => {
    if (projectLinkShortId) dispatch(validateProjectLink(projectLinkShortId));
    else dispatch(getInterviewData());
  }, []);

  useEffect(() => {
    if (project) {
      if (project.favicon_url) {
        setFavicon(project.favicon_url);
      } else {
        setFavicon("/favicon.png"); // Default favicon
      }
    } else {
      // If we don't have a project, try using the domain instead.
      const favicon = getFaviconForDomain(window.location.origin);
      if (favicon) setFavicon(favicon);
      // Otherwise use the default.
      else setFavicon("/favicon.png"); // Default favicon
    }
  }, [project]);

  const renderError = (errorText: string) => {
    return (
      <div className="p-6 text-center">
        <div className="text-xl font-medium text-gray-800">{errorText}</div>
        <div className="mt-2">
          Need help? Please reach out to us at {supportEmail}.
        </div>
      </div>
    );
  };

  const renderContent = () => {
    // Handle the landing page mode used by Fountain's applicant portal
    const { mode } = parseInterviewLinkParams(window.location.search);
    if (mode === InterviewPageSpecialModesEnum.InitialLandingPage && project) {
      return <InterviewLandingPage interview={interview} project={project} />;
    }

    // Supported browsers:
    // Desktop: Chrome, Edge, and Safari
    // iOS: Safari and Chrome
    // Android: Chrome
    const hasSupportedMobileBrowser = isIOS || isAndroid;
    const hasSupportedDesktopBrowser = isChrome || isEdge || isSafari;

    const hasSupportedBrowser = isMobile
      ? hasSupportedMobileBrowser
      : hasSupportedDesktopBrowser;

    if (!hasSupportedBrowser) {
      posthog.capture(PosthogEventTypesEnum.InterviewUnsupportedBrowser, {
        interview_id: interview?.id,
      });
      return renderError(
        "Sorry, your browser isn't supported yet. To continue, please use Chrome/Safari/Edge on desktop or Safari/Chrome on iOS/Android.",
      );
    }

    if (projectLinkShortId && projectLinkError) {
      return renderError(
        interviewAccessErrorEnumToErrorMessage[projectLinkError],
      );
    }

    if (projectLinkShortId && isEmailValidationRequired) {
      return renderError(
        "We've sent a link to continue your interview to your email address. Please check your inbox.",
      );
    }

    if (!projectLinkShortId) {
      if (
        interview_error &&
        interview_error === InterviewAccessErrorsEnum.LIMIT_REACHED
      ) {
        return renderError(
          interviewAccessErrorEnumToErrorMessage[interview_error],
        );
      }

      if (!interview || interview_error) {
        return renderError("Sorry, we couldn't find your interview.");
      }
    }

    if (interview && interview.status === "cancelled") {
      return renderError("Sorry, this interview has been cancelled.");
    }

    if (interview && interview.status === "completed") {
      return (
        <InterviewCompletedSection interview={interview} project={project} />
      );
    }

    // If the project requires video recording and the user is on mobile, force them to open the link on desktop
    if (project && project.should_record_video && isMobile) {
      return renderError(
        "Thank you for your interest in the role. This interview must be taken on a desktop or laptop computer. Please reopen the interview link on your computer to continue.",
      );
    }

    // TODO: Remove when we properly support video/mp4 streaming on Safari.
    if (project && project.should_record_video && !isChrome && !isEdge) {
      return renderError(
        "Thank you for your interest in the role. This interview must be taken using Chrome or Microsoft Edge. Please reopen the interview link in one of those browsers to continue.",
      );
    }

    // Retell-powered voice agent calls
    if (interview && project && project.mode === ProjectModesEnum.VOICE_AGENT) {
      return <VoiceAgentInterviewer interview={interview} project={project} />;
    }

    return <InterviewRecorder interview={interview} />;
  };

  const isLoading =
    (!project && projectLinkStatus === "loading") ||
    interview_loading_status === "loading";

  return (
    <div
      className={`flex flex-col ${
        isLoading ? "justify-center" : ""
      } sm:justify-center items-center min-h-[100dvh] h-screen`}
      style={{
        backgroundColor: isLoading
          ? "white"
          : project?.interview_color || "#172554",
      }}
    >
      {interview && interview.id === DEMO_INTERVIEW_ID ? (
        <DemoInterviewBanner />
      ) : null}
      {isLoading ? (
        <Loader style={LoaderStylesEnum.ZOOMIES} />
      ) : (
        <div className="bg-white min-h-full overflow-y-scroll sm:min-h-0 md:rounded-lg shadow-md w-full max-w-[800px] md:w-5/6 lg:w-2/3">
          {renderContent()}
        </div>
      )}
    </div>
  );
}

// NOTE: this only works on Chrome/Firefox, as Safari does not support dynamic favicon setting.
const setFavicon = (faviconUrl: string): void => {
  try {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    (link as HTMLLinkElement).type = "image/x-icon";
    (link as HTMLLinkElement).rel = "shortcut icon";
    (link as HTMLLinkElement).href = faviconUrl;

    // Append to head
    document.head.appendChild(link);
  } catch {
    console.error("Failed to set favicon");
  }
};

export default App;
