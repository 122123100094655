import { PhoneIcon } from "@heroicons/react/24/outline";
import {
  getDialingPhoneNumber,
  getDisplayPhoneNumber,
  INTERVIEW_LINK_PARAMS,
  InterviewPublic,
  ProjectPublic,
} from "app-types";
import { FC } from "react";
import { Button, ButtonVariantsEnum, SizesEnum } from "ui";
import { DEFAULT_WELCOME_MESSAGE } from "../interviewOnboarding/interviewWelcomePoints";

interface InterviewLandingPageProps {
  project: ProjectPublic;
  interview: InterviewPublic | null;
}

export const InterviewLandingPage: FC<InterviewLandingPageProps> = ({
  project,
  interview,
}) => {
  const contactFirstName = interview ? interview.contact.first_name : null;

  let displayPhoneNumber: string | null = null;
  let dialingPhoneNumber: string | null = null;
  const phoneNumberWithExtension = project.phone_number_with_extension;

  if (phoneNumberWithExtension) {
    displayPhoneNumber = getDisplayPhoneNumber(
      phoneNumberWithExtension.phone_number,
      phoneNumberWithExtension.extension,
    );
    dialingPhoneNumber = getDialingPhoneNumber(
      phoneNumberWithExtension.phone_number,
      phoneNumberWithExtension.extension,
    );
  }

  return (
    <div className="p-8">
      {project?.logo_url ? (
        <div className="flex mb-4 justify-center">
          <img
            src={project.logo_url}
            alt="Company logo"
            className="max-w-[170px] max-h-[50px]"
          />
        </div>
      ) : null}
      <h1 className="text-3xl font-bold mb-4 text-gray-800">
        {`Welcome${contactFirstName ? ", " + contactFirstName : ""}`}
      </h1>

      <div className="text-sm text-gray-800 mb-8">
        <p>
          {project.welcome_message
            ? project.welcome_message
            : DEFAULT_WELCOME_MESSAGE}
        </p>
      </div>
      <div className="flex flex-col items-center space-y-6">
        {phoneNumberWithExtension && (
          <>
            <div className="w-full max-w-[300px]">
              <Button
                variant={ButtonVariantsEnum.Primary}
                onClick={() =>
                  (window.location.href = `tel:${dialingPhoneNumber}`)
                }
                icon={<PhoneIcon className="h-5 w-5 mr-2" />}
                label={`Call ${displayPhoneNumber}`}
                className="w-full"
                size={SizesEnum.LARGE}
              />
              <div className="text-center mt-2 text-sm text-gray-500">
                Please call from the same number you provided in your
                application.
              </div>
            </div>

            <div className="text-center font-medium text-gray-700">OR</div>
          </>
        )}

        <div className="w-full max-w-[300px]">
          <Button
            variant={
              phoneNumberWithExtension
                ? ButtonVariantsEnum.Secondary
                : ButtonVariantsEnum.Primary
            }
            onClick={() => {
              // Update the current page URL without the landing page mode parameter
              const url = new URL(window.location.href);
              url.searchParams.delete(INTERVIEW_LINK_PARAMS.MODE);
              window.location.href = url.toString();
            }}
            label={"Complete interview in browser"}
            className="w-full"
            size={SizesEnum.LARGE}
          />
        </div>
      </div>
    </div>
  );
};
