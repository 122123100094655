import { CameraIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import { HandlerOf, InterviewDeviceTypesEnum } from "app-types";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  ModalVariantsEnum,
  SimpleModal,
  SizesEnum,
} from "ui";
import { InterviewDeviceModal } from "./interviewMicrophoneModal";

interface InterviewDeviceControlsProps {
  deviceType: InterviewDeviceTypesEnum;
  stopAnswering: HandlerOf<boolean>;
  devices: MediaDeviceInfo[];
  setDevices: HandlerOf<MediaDeviceInfo[]>;
  currentDeviceId: string | undefined;
  deviceIdToSwitchTo: string | undefined;
  setDeviceIdToSwitchTo: HandlerOf<string>;
  setDeviceError: (err: Error | null) => void;
  isRecordingAnswer: boolean;
  isReadOnly?: boolean;
}

const deviceTypeToKind = {
  [InterviewDeviceTypesEnum.MICROPHONE]: "audioinput",
  [InterviewDeviceTypesEnum.CAMERA]: "videoinput",
};

/*
 * This component loads the available mics or cameras on mount and starts/stops a stream to get the mic
 * or camera that's defaulted to
 * InterviewsDeviceModal allows the user to switch devices
 */

export const InterviewDeviceControls: FC<InterviewDeviceControlsProps> = ({
  deviceType,
  stopAnswering,
  devices,
  setDevices,
  currentDeviceId,
  deviceIdToSwitchTo,
  setDeviceError,
  setDeviceIdToSwitchTo,
  isRecordingAnswer,
  isReadOnly = false,
}) => {
  const [isChangeDeviceModalIsOpen, setIsChangeDeviceModalIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(devices.length === 0);

  // "Microphone" or "Camera"
  const deviceLabel = deviceType.charAt(0).toUpperCase() + deviceType.slice(1);

  const currentDevice =
    devices.find(
      (d) =>
        d.deviceId ===
        (isRecordingAnswer && currentDeviceId
          ? currentDeviceId
          : deviceIdToSwitchTo),
    ) ||
    devices.find((d) => d.deviceId === "default") || // Chrome-only "default" option
    (devices.length > 0 && devices[0]);

  const fetchDevices = async () => {
    setIsLoading(true);
    try {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const filteredDevices = allDevices.filter(
        (device) => device.kind === deviceTypeToKind[deviceType],
      );
      setDevices(filteredDevices);
    } catch (err) {
      // Set error
      if (err instanceof Error) setDeviceError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Load the microphones or cameras on initial load.
    if (devices.length === 0) fetchDevices();

    // Start and stop a stream to get the mic/camera that's being defaulted to.
    // User should already have granted permissions for this device.
    if (!currentDevice) {
      navigator.mediaDevices
        .getUserMedia({
          [deviceType === InterviewDeviceTypesEnum.MICROPHONE
            ? "audio"
            : "video"]: true,
        })
        .then((stream) => {
          // Get the device ID that was defaulted to (no reliable way to get this from "enumerateDevices")
          const track =
            deviceType === InterviewDeviceTypesEnum.MICROPHONE
              ? stream.getAudioTracks()[0]
              : stream.getVideoTracks()[0];
          const deviceId = track && track.getSettings().deviceId;
          if (deviceId) setDeviceIdToSwitchTo(deviceId);

          // Stop the stream
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((err) => {
          setDeviceError(err);
        });
    }
  }, []);

  if (isLoading && devices.length === 0) return null;

  if (!currentDevice)
    return (
      <SimpleModal
        isOpen={true}
        variant={ModalVariantsEnum.Standard}
        title={`No ${deviceType} found`}
        subtitle={`Please connect a ${deviceType}, ensure that you've granted ${deviceType} permissions, and refresh the page. Need help? Reach out to us at support@alpharun.com.`}
        confirmButtonText="Refresh this page"
        onConfirm={() => {
          window.location.reload();
        }}
      />
    );

  if (isReadOnly) {
    return (
      <div>
        <Label size={SizesEnum.SMALL} className="flex items-center">
          {deviceLabel}
        </Label>
        <div className="text-sm text-gray-600">
          {currentDevice?.label || `Unknown ${deviceType}`}
        </div>
      </div>
    );
  }

  return (
    <>
      <Button
        variant={ButtonVariantsEnum.Secondary}
        onClick={() => {
          stopAnswering(true);
          setIsChangeDeviceModalIsOpen(true);
        }}
        icon={
          deviceType === InterviewDeviceTypesEnum.MICROPHONE ? (
            <MicrophoneIcon className="h-[18px] w-[18px] mr-1" />
          ) : (
            <CameraIcon className="h-[18px] w-[18px] mr-1" />
          )
        }
        label={
          currentDevice?.label
            ? `${deviceLabel}: ${currentDevice.label}`
            : `Choose a ${deviceType}`
        }
      />
      {isChangeDeviceModalIsOpen && (
        <InterviewDeviceModal
          deviceType={deviceType}
          devices={devices}
          setDevices={setDevices}
          setDeviceError={setDeviceError}
          currentDeviceId={currentDevice.deviceId}
          onUserChangeDevice={(newDeviceId) => {
            setIsChangeDeviceModalIsOpen(false);
            setDeviceIdToSwitchTo(newDeviceId);
          }}
          fetchDevices={fetchDevices}
        />
      )}
    </>
  );
};
