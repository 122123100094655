import {
  getCandidateLanguageDescription,
  InterviewDeviceTypesEnum,
  InterviewLanguagesEnum,
  InterviewPublic,
  isRecruitingModeProject,
  LANGUAGE_TO_FLAG,
  noop,
  ProjectPublic,
  ResumeCollectionTypesEnum,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";
import { RetellWebClient } from "retell-client-js-sdk";
import {
  Button,
  ButtonVariantsEnum,
  Loader,
  LoaderStylesEnum,
  ModalVariantsEnum,
  SimpleModal,
} from "ui";
import { createAxiosInstance } from "../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { InterviewDeviceControls } from "../interview/interviewMicrophoneControls";
import { InterviewDeviceSetup } from "../interview/interviewMicrophoneSetup";
import { InterviewCompletedSection } from "../interviewCompleted/interviewCompletedSection";
import { ResumeUpload } from "../interviewCompleted/resumeUpload";
import {
  selectInterviewToken,
  selectIsResumingInterview,
  selectResumeAttachments,
} from "../transcriptFragments/transcriptFragmentsSlice";
import { CallTicker } from "./callTicker";
import { CameraPreview } from "./cameraPreview";
import { VideoRecorder } from "./videoRecorder";
import { setAlpharunCallId } from "./videoRecordingSlice";
import { WindowActivityLogger } from "./windowActivityLogger";

interface VoiceAgentInterviewerProps {
  interview: InterviewPublic;
  project: ProjectPublic;
}

const retellWebClient = new RetellWebClient();

// The minimum length of time for an interview to be considered completed
const MINIMUM_INTERVIEW_LENGTH_SECONDS = 20;

export const VoiceAgentInterviewer: FC<VoiceAgentInterviewerProps> = ({
  interview,
  project,
}) => {
  const dispatch = useAppDispatch();
  const isResumingInterview = useAppSelector(selectIsResumingInterview);

  // Call states
  const [isCalling, setIsCalling] = useState(false);
  const [hasEndedCall, setHasEndedCall] = useState(false);
  const [isEndCallModalOpen, setIsEndCallModalOpen] = useState(false);
  const [callStartTime, setCallStartTime] = useState<number | null>(null);
  const [callDuration, setCallDuration] = useState<number | null>(null);
  const isInterviewTooShort =
    callDuration && callDuration < MINIMUM_INTERVIEW_LENGTH_SECONDS;

  // Device states
  const [deviceError, setDeviceError] = useState<Error | null>(null);
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [microphoneIdToSwitchTo, setMicrophoneIdToSwitchTo] = useState<
    string | undefined
  >(undefined);
  const [micPermission, setMicPermission] = useState<
    PermissionState | undefined
  >();
  const [cameraIdToSwitchTo, setCameraIdToSwitchTo] = useState<
    string | undefined
  >(undefined);
  const [cameraPermission, setCameraPermission] = useState<
    PermissionState | undefined
  >();

  const interviewToken = useSelector(selectInterviewToken);
  const [retellError, setRetellError] = useState<string | null>(null);

  const resumes = useSelector(selectResumeAttachments);

  const isResumeRequired =
    project.resume_collection_type ===
    ResumeCollectionTypesEnum.BeforeInterview;

  const isRecruitingMode = project && isRecruitingModeProject(project);

  const [isInitiatingCall, setIsInitiatingCall] = useState(false);

  const isDevicePermissionDenied =
    micPermission !== "granted" ||
    (project.should_record_video && cameraPermission !== "granted");
  const shouldShowCameraPreview =
    project.should_record_video && cameraPermission === "granted" && !isCalling;

  useEffect(() => {
    retellWebClient.on("call_started", () => {
      setIsCalling(true);
      setRetellError(null);
    });

    retellWebClient.on("call_ended", () => {
      setIsCalling(false);
      setHasEndedCall(true);
    });

    retellWebClient.on("error", (error) => {
      console.error("An error occurred:", error);
      setRetellError(
        "An error occurred during the call. Please try again or email support@alpharun.com.",
      );
      retellWebClient.stopCall();
      setIsCalling(false);
    });
  }, []);

  const startCall = async () => {
    setIsInitiatingCall(true);
    setRetellError(null);

    try {
      if (project.should_force_fullscreen) {
        try {
          await document.documentElement.requestFullscreen();
        } catch (error) {
          console.warn("Failed to enter fullscreen mode:", error);
        }
      }

      // Retrieve a retell access token for the call
      const axiosInstance = createAxiosInstance(interviewToken);
      const response = await axiosInstance.post(
        "interview/initiate-voice-call",
      );
      const { call_token, alpharun_call_id } = response.data;

      dispatch(setAlpharunCallId(alpharun_call_id));

      await retellWebClient.startCall({
        accessToken: call_token,
        captureDeviceId: microphoneIdToSwitchTo,
      });

      setIsCalling(true);
      setHasEndedCall(false);
      setCallDuration(null);
      setCallStartTime(Date.now());
    } catch (error) {
      console.error("Failed to start call:", error);
      setRetellError(
        "Failed to start the call. Please try again or email support@alpharun.com.",
      );
    } finally {
      setIsInitiatingCall(false);
    }
  };

  const stopCall = () => {
    retellWebClient.stopCall();
    setIsCalling(false);
    setHasEndedCall(true);
    setIsEndCallModalOpen(false);

    // Exit fullscreen if we're in it
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((error) => {
        console.warn("Error exiting fullscreen:", error);
      });
    }

    if (callStartTime) {
      setCallDuration((Date.now() - callStartTime) / 1000); // Convert to seconds
    }
  };

  const maybeRenderResumeUpload = () => {
    if (!isResumeRequired) return null;

    // If the interview is too short, the user already added their resume
    // but ended their interview early.
    if (isInterviewTooShort) {
      return null;
    }

    return (
      <ResumeUpload resumeCollectionType={project.resume_collection_type} />
    );
  };

  const maybeRenderLanguageNotice = () => {
    if (project.interview_language === InterviewLanguagesEnum.ENGLISH) {
      return null;
    }

    return (
      <div>
        <div className="inline-block mb-4 text-sm bg-blue-50 text-blue-700 p-3 rounded-md">
          <span className="mr-2">
            {LANGUAGE_TO_FLAG[project.interview_language]}
          </span>
          {getCandidateLanguageDescription(project.interview_language)}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isDevicePermissionDenied || deviceError) {
      return (
        <div className="flex flex-col justify-center items-center min-h-[140px]">
          <InterviewDeviceSetup
            deviceError={deviceError}
            setDeviceError={setDeviceError}
            micPermission={micPermission}
            setMicPermission={setMicPermission}
            cameraPermission={cameraPermission}
            setCameraPermission={setCameraPermission}
          />
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center space-y-4">
        <div className="flex flex-col items-center space-y-3 pt-4">
          {isCalling ? (
            <>
              <CallTicker />
              <div className="pt-2">
                <Button
                  onClick={() => setIsEndCallModalOpen(true)}
                  variant={ButtonVariantsEnum.Warning}
                  label="End Call"
                />
              </div>
              <div className="text-sm text-gray-600">
                When you've completed your interview you can end the call.
              </div>
            </>
          ) : isInitiatingCall ? (
            <>
              <Loader style={LoaderStylesEnum.ZOOMIES} />
              <p className="text-sm text-gray-600">
                Starting your interview...
              </p>
            </>
          ) : (
            <>
              {maybeRenderResumeUpload()}
              {isInterviewTooShort ? (
                <p className="text-sm text-gray-600">
                  It looks like you didn't complete your interview. You can
                  restart your interview when you're ready.
                </p>
              ) : null}
              {retellError && (
                <p className="text-sm text-red-600 mb-2">{retellError}</p>
              )}
              <Button
                onClick={startCall}
                variant={ButtonVariantsEnum.Primary}
                label={
                  isInterviewTooShort || isResumingInterview
                    ? "Resume Interview"
                    : "Start Interview"
                }
                isDisabled={isResumeRequired && resumes.length === 0}
              />
            </>
          )}
        </div>
        {isDesktop && (
          <div className="pt-8 w-full">
            <div className="flex flex-wrap gap-8">
              <div className="space-y-2 flex-shrink-0">
                <InterviewDeviceControls
                  deviceType={InterviewDeviceTypesEnum.MICROPHONE}
                  stopAnswering={noop}
                  devices={microphones}
                  setDevices={setMicrophones}
                  currentDeviceId={undefined}
                  deviceIdToSwitchTo={microphoneIdToSwitchTo}
                  setDeviceIdToSwitchTo={setMicrophoneIdToSwitchTo}
                  setDeviceError={setDeviceError}
                  isRecordingAnswer={isCalling}
                  isReadOnly={isCalling}
                />

                {project.should_record_video && (
                  <InterviewDeviceControls
                    deviceType={InterviewDeviceTypesEnum.CAMERA}
                    stopAnswering={noop}
                    devices={cameras}
                    setDevices={setCameras}
                    currentDeviceId={undefined}
                    deviceIdToSwitchTo={cameraIdToSwitchTo}
                    setDeviceIdToSwitchTo={setCameraIdToSwitchTo}
                    setDeviceError={setDeviceError}
                    isRecordingAnswer={isCalling}
                    isReadOnly={isCalling}
                  />
                )}
              </div>

              {shouldShowCameraPreview && cameraIdToSwitchTo && (
                <div className="md:ml-auto">
                  <CameraPreview width={120} deviceId={cameraIdToSwitchTo} />
                </div>
              )}
            </div>
          </div>
        )}
        <VideoRecorder
          isCalling={isCalling}
          shouldRecordVideo={project.should_record_video}
          deviceId={cameraIdToSwitchTo}
        />
      </div>
    );
  };

  // Case where the user has completed a voice interview of at least 20 seconds
  if (hasEndedCall && !isInterviewTooShort) {
    return (
      <InterviewCompletedSection interview={interview} project={project} />
    );
  }

  return (
    <div className="flex flex-col p-8">
      <h1 className="text-3xl font-semibold mb-4 text-gray-800">
        {isResumingInterview ? `Welcome back` : "Welcome"}
      </h1>
      <div className="text-sm text-gray-800">
        <div className="mb-2 text-gray-700">
          {`${
            isResumingInterview ? "You can pick up where you left off. " : ""
          }We appreciate your interest in this opportunity and look forward to learning more about you.`}
        </div>
        {isRecruitingMode && (
          <div className="mb-4 text-gray-700">
            During the interview, please{" "}
            <strong>
              {project.should_force_fullscreen &&
              (isInitiatingCall || isCalling)
                ? "do not leave your fullscreen browser window"
                : "do not leave your browser window or consult any outside resources"}
            </strong>
            . Just focus on sharing your own honest answers.
          </div>
        )}
      </div>
      {maybeRenderLanguageNotice()}
      {renderContent()}
      <SimpleModal
        isOpen={isEndCallModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="End Call"
        subtitle={
          isInterviewTooShort
            ? "Are you sure? We recommend completing your interview in one session but you can resume the interview later if needed."
            : "Are you sure you want to end the interview?"
        }
        confirmButtonText="End Interview"
        onCancel={() => setIsEndCallModalOpen(false)}
        onConfirm={stopCall}
      />
      {(isInitiatingCall || isCalling) && isRecruitingMode && (
        <WindowActivityLogger
          shouldForceFullscreen={project.should_force_fullscreen}
        />
      )}
    </div>
  );
};
