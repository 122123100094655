import { FC, useEffect, useState } from "react";

interface CameraPreviewProps {
  width: number;
  deviceId: string;
}

export const CameraPreview: FC<CameraPreviewProps> = ({ width, deviceId }) => {
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const startCamera = async () => {
      try {
        // Stop any existing tracks
        if (videoStream) {
          videoStream.getTracks().forEach((track) => track.stop());
        }

        const constraints: MediaStreamConstraints = {
          video: { deviceId: { exact: deviceId } },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setVideoStream(stream);
        setError("");
      } catch (err) {
        console.error("Error accessing camera:", err);
        setError(
          "An error occurred accessing your camera. Please check your camera permissions.",
        );
      }
    };

    startCamera();

    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [deviceId]);

  if (error) {
    return (
      <div className="space-y-2">
        <p className="text-sm text-red-600">{error}</p>
      </div>
    );
  }

  if (!videoStream) return null;

  return (
    <div
      className="rounded-lg overflow-hidden border border-gray-200"
      style={{ width: `${width}px` }}
    >
      <video
        autoPlay
        playsInline
        muted
        ref={(videoElement) => {
          if (videoElement) {
            videoElement.srcObject = videoStream;
          }
        }}
        className="w-full scale-x-[-1]"
      />
    </div>
  );
};
