import type {
  TablesInsertWithFixedTypes,
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
} from "../db/supabase_with_fixed_types.ts";
import type {
  AttachmentMetadata,
  AttachmentResourceType,
  AttachmentType,
} from "../db/types.ts";

export const allowedResumeMimeTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "application/rtf",
  "text/rtf",
];

export type AttachmentDB = TablesWithFixedTypes<"attachment">;
export type AttachmentInsert = TablesInsertWithFixedTypes<"attachment">;
export type AttachmentUpdate = TablesUpdateWithFixedTypes<"attachment">;

export interface Attachment {
  id: string;
  type: AttachmentType;
  company_id: string;
  file_path: string;
  resource_id: string;
  resource_type: AttachmentResourceType;
  metadata: AttachmentMetadata;
  created_at: string;
}

export interface AttachmentPublic {
  id: string;
  type: AttachmentType;
  file_path: string;
}

export function mapAttachmentDBToAttachment(data: AttachmentDB): Attachment {
  return {
    id: data.id,
    type: data.type,
    company_id: data.company_id,
    created_at: data.created_at,
    file_path: data.file_path,
    resource_id: data.resource_id,
    resource_type: data.resource_type,
    metadata: mapAttachmentMetadataDbToAttachmentMetadata(data.metadata),
  };
}

const mapAttachmentMetadataDbToAttachmentMetadata = (
  data: AttachmentMetadata | null,
): AttachmentMetadata => {
  // If metadata is not set on attachment, use '{text: null}' as default value
  return (
    data || {
      text: null,
    }
  );
};

export const attachmentToAttachmentPublic = (
  file: Attachment,
): AttachmentPublic => {
  return {
    id: file.id,
    type: file.type,
    file_path: file.file_path,
  };
};
