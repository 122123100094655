import {
  ComplexInterviewQuestion,
  InterviewPublic,
  isComplexInterviewQuestion,
  WHITELABEL_SUPPORT_EMAILS,
  WhitelabelDomainsEnum,
} from "app-types";
import { ComplexAnswerPublic } from "../features/complexAnswers/complexAnswersSlice";

export const getUnansweredSurveyQuestion = (
  interview: InterviewPublic,
  lastAnswer?: ComplexAnswerPublic, // Question we just answered, for case where interview.questions hasn't been updated yet
) => {
  return interview.questions.find((q) => {
    if (isComplexInterviewQuestion(q)) {
      return (
        q.has_been_answered === false &&
        (!lastAnswer || q.id !== lastAnswer?.question_id)
      );
    }

    return false;
  }) as ComplexInterviewQuestion | undefined; // Typecast bc we know the question must be complex if found
};

// Used to set the token in the URL when initiated via a project link.
// Currently only used when a project link is on a whitelabel domain.
export const setInterviewTokenOnUrl = (token: string) => {
  const newUrl = new URL(window.location.href);
  newUrl.pathname = "/i";
  newUrl.searchParams.set("t", token);
  window.history.replaceState({}, "", newUrl.toString());
};

// Returns a custom whitelabel support email if the domain matches a whitelabel domain.
export const getSupportEmail = () => {
  const currentDomain = window.location.origin;
  const matchingDomain = Object.values(WhitelabelDomainsEnum).find(
    (domain) => domain === currentDomain,
  );

  if (matchingDomain) {
    return WHITELABEL_SUPPORT_EMAILS[matchingDomain as WhitelabelDomainsEnum];
  }

  return "support@alpharun.com";
};

export const debounce = (fn: Function, delay: number) => {
  let timeoutId: number;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};
